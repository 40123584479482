export enum Resource {
  AccessGrantData = 'access_grant_data',
  AccessMyGrants = 'access_my_grants',
  AccessReportingDashboard = 'access_reporting_dashboard',
  AccessSubrecipientData = 'access_subrecipient_data',
  AccessSystemAdmin = 'access_system_admin',
  ApproveFparData = 'approve_fpar_data',
  CreateNewAccount = 'create_new_account',
  CreateNewAllDataAnalyst = 'create_new_all_data_analyst',
  CreateNewDataCoordinator = 'create_new_data_coordinator',
  CreateNewGrantAdmin = 'create_new_grant_admin',
  CreateNewGrantDataAnalyst = 'create_new_grant_data_analyst',
  CreateNewGrantUser = 'create_new_grant_user',
  CreateNewProjectOfficer = 'create_new_project_officer',
  CreateNewSubmissionComment = 'create_new_submission_comment',
  CreateNewSubrecipientDataAnalyst = 'create_new_subrecipient_data_analyst',
  CreateNewSubrecipientUser = 'create_new_subrecipient_user',
  CreateNewSystemSupportStaff = 'create_new_system_support_staff',
  DisableExistingAccount = 'disable_existing_account',
  EditAccountDetails = 'edit_account_details',
  EditSubmissionComment = 'edit_submission_comment',
  EditSupplementalInformation = 'edit_supplemental_information',
  EnterGrantPerformanceMeasures = 'enter_grant_performance_measures',
  ListAssignableRoles = 'list_assignable_roles',
  ListGrants = 'list_grants',
  ListPermissions = 'list_permissions',
  ListUsers = 'list_users',
  ReceiveNewAccountNotification = 'receive_new_account_notification',
  RemoveGrantAssociation = 'remove_grant_association',
  ResetPassword = 'reset_password',
  SubmitGrantForOpaReview = 'submit_grant_for_opa_review',
  UpdateProfileCoverSheet = 'update_profile_cover_sheet',
  UploadEncounterData = 'upload_encounter_data',
  ValidateFparData = 'validate_fpar_data',
  ViewDashboardGrantSelector = 'view_dashboard_grant_selector',
  ViewDataStatusReport = 'view_data_status_report',
  ViewGrantPortal = 'view_grant_portal',
  ViewMyFparPage = 'view_my_fpar_page',
  ViewProfileCoverSheet = 'view_profile_cover_sheet',
  ViewReviewPortal = 'view_review_portal',
  ViewTableArchiveGrantSelector = 'view_table_archive_grant_selector',
  ViewValidationReport = 'view_validation_report',
  // Resource permissions below may not be necessary.
  // They were brought in to match the list of permissions in the db.
  AcceptSubmission = 'accept_submission',
  AccessTableArchive = 'access_table_archive',
  CreateNewReportingPeriod = 'create_new_reporting_period',
  EditComments = 'edit_comments',
  EditDataQualityIssue = 'edit_data_quality_issue',
  EditReportingPeriod = 'edit_reporting_period',
  EnterGrantContactInfo = 'enter_grant_contact_info',
  GenerateDashboardData = 'generate_dashboard_data',
  RemoveAllUsers = 'remove_all_users',
  ReturnSubmission = 'return_submission',
  UpdateGrantAttributes = 'update_grant_attributes',
  UpdateSubmissionStatus = 'update_submission_status',
  ViewRemovedUserReport = 'view_removed_user_report',
  ViewSites = 'view_sites',
  ViewSubmissionReport = 'view_submission_report',
  ViewSubrecipients = 'view_subrecipients',
}
